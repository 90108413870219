body {
  /* background-color: aqua; */
}

.App {
  text-align: center;
  height: 100vh;
  /* background-color: brown; */
}

.Content {
  display: flex;
  flex-direction: row;

  justify-content: center;
  flex-wrap: nowrap;
}

.PageNav {
}
.PageFeed {
}

.PageFooter {
}
