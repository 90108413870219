.clip-circle {
  width: 100px;
  height: 100px;
  clip-path: circle(40px at center);
  position: relative;
  top: 0px;
}
.profile {
  position: absolute;
  top: 95px;
  padding: 10px;
  width: 100px;
  height: 100px;
  clip-path: circle(43px at center);
  background-color: white;
}
.logo {
  width: 620px;
  background-color: lightgrey;
}
.profileBox {
  position: relative;
}

.profileText {
  display: flex;

  padding-top: 50px;
  padding-bottom: 40px;
  padding-left: 20px;

  display: flex;
  flex-direction: column;
}

h1 {
  display: flex;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 1.5em;
  font-weight: 500;
  margin: 0;
}
h2 {
  display: flex;
  margin: 0;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

  font-size: 0.8em;
  font-weight: 400;
  color: grey;
}
