.NavButton {
  color: grey;
  font-size: 1.5em;
  border: none;
  background: none;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 2%;
}
.NavButton:hover {
  border-radius: 25px;
  background-color: rgba(1, 126, 255, 0.1);

  color: rgb(1, 126, 255);
}

.ButtonText {
  display: flex;
  justify-content: left;
  width: 60%;
}
